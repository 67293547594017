import React from 'react';
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Wallets from "./pages/Wallets/Wallets";
import Home from "./pages/Home/Home";

const router = createBrowserRouter([
    {
        path: "/",
        index: true,
        element: <Home />,
    },
    {
        path: "wallets",
        element: <Wallets />,
    },
]);

const App = () => {
    return <RouterProvider router={router} />;
};

export default App;
